import { default as indexr6pXQuVmw9Meta } from "/home/forge/pos-pre.terradev.app/pages/branch-selector/index.vue?macro=true";
import { default as empty_45pageaJeJ9Ba3NwMeta } from "/home/forge/pos-pre.terradev.app/pages/empty-page.vue?macro=true";
import { default as exampleD96Z7KqW44Meta } from "/home/forge/pos-pre.terradev.app/pages/example.vue?macro=true";
import { default as forgot_45passwordCLD4ghfpCnMeta } from "/home/forge/pos-pre.terradev.app/pages/forgot-password.vue?macro=true";
import { default as indexBUKMKUxoetMeta } from "/home/forge/pos-pre.terradev.app/pages/index.vue?macro=true";
import { default as editEjL42CzkmxMeta } from "/home/forge/pos-pre.terradev.app/pages/management/admin/[id]/edit.vue?macro=true";
import { default as indext2Fs3RTPm4Meta } from "/home/forge/pos-pre.terradev.app/pages/management/admin/[id]/index.vue?macro=true";
import { default as indexC9vreR5xq7Meta } from "/home/forge/pos-pre.terradev.app/pages/management/admin/index.vue?macro=true";
import { default as newBEzRvGbatEMeta } from "/home/forge/pos-pre.terradev.app/pages/management/admin/new.vue?macro=true";
import { default as edit8qpvFY1e9GMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/[id]/edit.vue?macro=true";
import { default as indexfbnY2NGBseMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/[id]/index.vue?macro=true";
import { default as edit45PlMkuVdVMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/group/[id]/edit.vue?macro=true";
import { default as index8WoB43rFuEMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/group/[id]/index.vue?macro=true";
import { default as indexJSSffYZpWBMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/group/index.vue?macro=true";
import { default as newjy55DfVsPNMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/group/new.vue?macro=true";
import { default as indexq6IZqp4FE7Meta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/index.vue?macro=true";
import { default as editQYIXJigVxDMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/[id]/edit.vue?macro=true";
import { default as indexWjDWJkgElJMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/[id]/index.vue?macro=true";
import { default as indexbAjmHOZH7xMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/index.vue?macro=true";
import { default as newHIQw3IrEymMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/new.vue?macro=true";
import { default as newCTFyAtIDRsMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/new.vue?macro=true";
import { default as editInKoXORP3jMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/payment/[id]/edit.vue?macro=true";
import { default as indexj42pWkEvAMMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/payment/[id]/index.vue?macro=true";
import { default as indexzA6Zgdb7jaMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/payment/index.vue?macro=true";
import { default as newS0WmxrrEXdMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/payment/new.vue?macro=true";
import { default as editN5hDLG5LT3Meta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/[id]/edit.vue?macro=true";
import { default as index8DNKY5QUQnMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/[id]/index.vue?macro=true";
import { default as edit7e4Lu9eRCtMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/[id]/edit.vue?macro=true";
import { default as indexPI8iMGk6oxMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/[id]/index.vue?macro=true";
import { default as indext4WlLk2JLyMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/index.vue?macro=true";
import { default as newHVee1Avj3aMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/new.vue?macro=true";
import { default as indexiE0lDYzCg6Meta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/index.vue?macro=true";
import { default as newVYsZwcflufMeta } from "/home/forge/pos-pre.terradev.app/pages/management/branch/printer/new.vue?macro=true";
import { default as indexPJfuko6p0fMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/index.vue?macro=true";
import { default as indexno7YjNf7WSMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/[bid]/index.vue?macro=true";
import { default as _91tid_93A2hg5621cVMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/[bid]/table/[tid].vue?macro=true";
import { default as indexfyGyauCbTaMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/index.vue?macro=true";
import { default as monthly36H0jDc0GUMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/report/monthly.vue?macro=true";
import { default as _91oid_93lwpE47B1FoMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/report/order/[bid]/[oid].vue?macro=true";
import { default as todayiNe6p7yxCnMeta } from "/home/forge/pos-pre.terradev.app/pages/management/executive/report/today.vue?macro=true";
import { default as indexP8xzbNKgteMeta } from "/home/forge/pos-pre.terradev.app/pages/management/index.vue?macro=true";
import { default as _91code_93DxwLRh9r16Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/[code].vue?macro=true";
import { default as edit574jxCCoKPMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/[id]/edit.vue?macro=true";
import { default as indexNaR0j9Y76ZMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/[id]/index.vue?macro=true";
import { default as indexJsy7JqydUXMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/index.vue?macro=true";
import { default as new0MTOIl1SP5Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/new.vue?macro=true";
import { default as generateLx5UdzyeGbMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/generate.vue?macro=true";
import { default as indexhYKSULBiwuMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/index.vue?macro=true";
import { default as scanorSlrXR89HMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/scan.vue?macro=true";
import { default as index7dqwCT7X3AMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/index.vue?macro=true";
import { default as receive1vAN91Wpx1Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/receive.vue?macro=true";
import { default as sendlPSl90DYuaMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/send.vue?macro=true";
import { default as _91code_93Fg4yejzKBoMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/[code].vue?macro=true";
import { default as editd4u5RiQLuhMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/[id]/edit.vue?macro=true";
import { default as index9REH3MCKz1Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/[id]/index.vue?macro=true";
import { default as indexHXvGRLYhqSMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/index.vue?macro=true";
import { default as newpGmRpnRwG3Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/new.vue?macro=true";
import { default as generateEqnBsTaQPmMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/generate.vue?macro=true";
import { default as index1ccump0hTzMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/index.vue?macro=true";
import { default as editCX91bL4e7TMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/[id]/edit.vue?macro=true";
import { default as indexywEUYoYzjmMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/[id]/index.vue?macro=true";
import { default as indexoaoHUk4KrjMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/index.vue?macro=true";
import { default as newjjRAOVbAP5Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/new.vue?macro=true";
import { default as scanZGtuwBAJvQMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/scan.vue?macro=true";
import { default as editWhc3StBCoGMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/[id]/edit.vue?macro=true";
import { default as indexNVoqAQ1BJ2Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/[id]/index.vue?macro=true";
import { default as indexVtOQU31c2RMeta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/index.vue?macro=true";
import { default as newLzOWZStlS3Meta } from "/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/new.vue?macro=true";
import { default as editUBEkaUZsCRMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/[id]/edit.vue?macro=true";
import { default as indexSdhs4HkBB1Meta } from "/home/forge/pos-pre.terradev.app/pages/management/material/[id]/index.vue?macro=true";
import { default as editTvRmrNyyCnMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/group/[id]/edit.vue?macro=true";
import { default as indexDupR9snyNuMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/group/[id]/index.vue?macro=true";
import { default as indexPaGXuzq72lMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/group/index.vue?macro=true";
import { default as newDSt6tG9K6eMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/group/new.vue?macro=true";
import { default as indexQvnfp2c6H9Meta } from "/home/forge/pos-pre.terradev.app/pages/management/material/index.vue?macro=true";
import { default as newR519U5VWxMMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/new.vue?macro=true";
import { default as indexA8aWvXsMpdMeta } from "/home/forge/pos-pre.terradev.app/pages/management/material/stock/index.vue?macro=true";
import { default as editjSvzc3ZnBoMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/[id]/edit.vue?macro=true";
import { default as indexQcxsFCZ3uLMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/[id]/index.vue?macro=true";
import { default as edit4F9Ai45CqAMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/group/[id]/edit.vue?macro=true";
import { default as indexQ0IAyIpIvbMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/group/[id]/index.vue?macro=true";
import { default as indexdu3U2FI7rtMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/group/index.vue?macro=true";
import { default as index5TnqLbFmwKMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/group/new/index.vue?macro=true";
import { default as _91parent_93jPAeHbLQsfMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/group/new/sub/[parent].vue?macro=true";
import { default as indexUFdyypOG4UMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/index.vue?macro=true";
import { default as newtPfFrnGK8rMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/new.vue?macro=true";
import { default as editJD7ZKjHWXVMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/[id]/edit.vue?macro=true";
import { default as indextPCFwGXFH9Meta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/[id]/index.vue?macro=true";
import { default as editdQZOymXqBlMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/[id]/edit.vue?macro=true";
import { default as indexVDVy9aXqOjMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/[id]/index.vue?macro=true";
import { default as indexOmfcGItSz4Meta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/index.vue?macro=true";
import { default as newH6RMjSLjuXMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/new.vue?macro=true";
import { default as indexhks6NHGYorMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/index.vue?macro=true";
import { default as new0vNIDHP6qxMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/new.vue?macro=true";
import { default as editXPwVZnkcOOMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/[id]/edit.vue?macro=true";
import { default as indexNGeRbehpvqMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/[id]/index.vue?macro=true";
import { default as editfpZPrBSBMcMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/[id]/edit.vue?macro=true";
import { default as indexhCXv7BjkUMMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/[id]/index.vue?macro=true";
import { default as indexozUMJbaTk0Meta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/index.vue?macro=true";
import { default as newBbB7B3ptOYMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/new.vue?macro=true";
import { default as indexncy24TFNOrMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/index.vue?macro=true";
import { default as newlasJmTRZBFMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/topping/new.vue?macro=true";
import { default as editA73hdd8kMQMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/type/[id]/edit.vue?macro=true";
import { default as indexPO3mX6XEPBMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/type/[id]/index.vue?macro=true";
import { default as indexZ7lXobhKzvMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/type/index.vue?macro=true";
import { default as newqfmym6ltHDMeta } from "/home/forge/pos-pre.terradev.app/pages/management/menu/type/new.vue?macro=true";
import { default as indexTsMAFFRlHKMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/activity/index.vue?macro=true";
import { default as indexq4kA5tNWpjMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/employee/index.vue?macro=true";
import { default as indexITVgELVuaRMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/payment/index.vue?macro=true";
import { default as indexoqCTjpLzI6Meta } from "/home/forge/pos-pre.terradev.app/pages/management/report/price-report/index.vue?macro=true";
import { default as indexySY8zrfIQ5Meta } from "/home/forge/pos-pre.terradev.app/pages/management/report/product/index.vue?macro=true";
import { default as _91id_93Vjrp1HpmNaMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/shift/[id].vue?macro=true";
import { default as indexJlZxCH302jMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/shift/index.vue?macro=true";
import { default as indexMgx52NQ1XWMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/stock/index.vue?macro=true";
import { default as indexBf4C2D7TegMeta } from "/home/forge/pos-pre.terradev.app/pages/management/report/summary/index.vue?macro=true";
import { default as indexSxkpBKfVp0Meta } from "/home/forge/pos-pre.terradev.app/pages/management/report/void/index.vue?macro=true";
import { default as editA5U98gtFU5Meta } from "/home/forge/pos-pre.terradev.app/pages/management/table/[id]/edit.vue?macro=true";
import { default as indexGkop1DBYZvMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/[id]/index.vue?macro=true";
import { default as generateCpY8FtVDwzMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/generate.vue?macro=true";
import { default as indexO2Z0ukG1lDMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/index.vue?macro=true";
import { default as neww4ZmUiKjfuMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/new.vue?macro=true";
import { default as edit5UxkF9ZkD7Meta } from "/home/forge/pos-pre.terradev.app/pages/management/table/zone/[id]/edit.vue?macro=true";
import { default as index0mTNj915e4Meta } from "/home/forge/pos-pre.terradev.app/pages/management/table/zone/[id]/index.vue?macro=true";
import { default as index65H7rSQFvlMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/zone/index.vue?macro=true";
import { default as newhgRZdmsqkoMeta } from "/home/forge/pos-pre.terradev.app/pages/management/table/zone/new.vue?macro=true";
import { default as editYM4jel9WA3Meta } from "/home/forge/pos-pre.terradev.app/pages/management/terminal/[id]/edit.vue?macro=true";
import { default as indexBgTwM7hDfmMeta } from "/home/forge/pos-pre.terradev.app/pages/management/terminal/[id]/index.vue?macro=true";
import { default as indexiW01TDX6RRMeta } from "/home/forge/pos-pre.terradev.app/pages/management/terminal/index.vue?macro=true";
import { default as new2h6nku7QbKMeta } from "/home/forge/pos-pre.terradev.app/pages/management/terminal/new.vue?macro=true";
import { default as editk15E1kIcH0Meta } from "/home/forge/pos-pre.terradev.app/pages/management/user/[id]/edit.vue?macro=true";
import { default as index37lgtSYzGmMeta } from "/home/forge/pos-pre.terradev.app/pages/management/user/[id]/index.vue?macro=true";
import { default as indexS4CPJ9n9fFMeta } from "/home/forge/pos-pre.terradev.app/pages/management/user/index.vue?macro=true";
import { default as pin_45loginLE3RACtrClMeta } from "/home/forge/pos-pre.terradev.app/pages/pin-login.vue?macro=true";
import { default as profile3ePbPk8sUdMeta } from "/home/forge/pos-pre.terradev.app/pages/profile.vue?macro=true";
import { default as _91id_93uzTjIVzMnnMeta } from "/home/forge/pos-pre.terradev.app/pages/sale/activity/[no]/[id].vue?macro=true";
import { default as indexZua8Q3gfp2Meta } from "/home/forge/pos-pre.terradev.app/pages/sale/activity/index.vue?macro=true";
import { default as indexDra4eRKlAmMeta } from "/home/forge/pos-pre.terradev.app/pages/sale/index.vue?macro=true";
import { default as indexxcPJTzeyWUMeta } from "/home/forge/pos-pre.terradev.app/pages/sale/tables/index.vue?macro=true";
import { default as indexXePfKaP9E1Meta } from "/home/forge/pos-pre.terradev.app/pages/sale/takeaway/index.vue?macro=true";
import { default as _91id_93CPelJTqf8GMeta } from "/home/forge/pos-pre.terradev.app/pages/terminal-access/[id].vue?macro=true";
export default [
  {
    name: "branch-selector",
    path: "/branch-selector",
    meta: indexr6pXQuVmw9Meta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/branch-selector/index.vue").then(m => m.default || m)
  },
  {
    name: "empty-page",
    path: "/empty-page",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/empty-page.vue").then(m => m.default || m)
  },
  {
    name: "example",
    path: "/example",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/example.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordCLD4ghfpCnMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexBUKMKUxoetMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-id-edit",
    path: "/management/admin/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/admin/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-id",
    path: "/management/admin/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/admin/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin",
    path: "/management/admin",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-new",
    path: "/management/admin/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/admin/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-id-edit",
    path: "/management/branch/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-id",
    path: "/management/branch/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-id-edit",
    path: "/management/branch/group/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-id",
    path: "/management/branch/group/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group",
    path: "/management/branch/group",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-new",
    path: "/management/branch/group/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch",
    path: "/management/branch",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-id-edit",
    path: "/management/branch/kitchen/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-id",
    path: "/management/branch/kitchen/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen",
    path: "/management/branch/kitchen",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-new",
    path: "/management/branch/kitchen/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/kitchen/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-new",
    path: "/management/branch/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-id-edit",
    path: "/management/branch/payment/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/payment/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-id",
    path: "/management/branch/payment/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/payment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment",
    path: "/management/branch/payment",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-new",
    path: "/management/branch/payment/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/payment/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-id-edit",
    path: "/management/branch/printer/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-id",
    path: "/management/branch/printer/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-id-edit",
    path: "/management/branch/printer/agent/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-id",
    path: "/management/branch/printer/agent/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent",
    path: "/management/branch/printer/agent",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-new",
    path: "/management/branch/printer/agent/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/agent/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer",
    path: "/management/branch/printer",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-new",
    path: "/management/branch/printer/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/branch/printer/new.vue").then(m => m.default || m)
  },
  {
    name: "management-executive",
    path: "/management/executive",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch-bid",
    path: "/management/executive/live/branch/:bid()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/[bid]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch-bid-table-tid",
    path: "/management/executive/live/branch/:bid()/table/:tid()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/[bid]/table/[tid].vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch",
    path: "/management/executive/live/branch",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/live/branch/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-monthly",
    path: "/management/executive/report/monthly",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/report/monthly.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-order-bid-oid",
    path: "/management/executive/report/order/:bid()/:oid()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/report/order/[bid]/[oid].vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-today",
    path: "/management/executive/report/today",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/executive/report/today.vue").then(m => m.default || m)
  },
  {
    name: "management",
    path: "/management",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-code",
    path: "/management/keep/beverage/:code()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/[code].vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-id-edit",
    path: "/management/keep/beverage/brand/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-id",
    path: "/management/keep/beverage/brand/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand",
    path: "/management/keep/beverage/brand",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-new",
    path: "/management/keep/beverage/brand/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/brand/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-generate",
    path: "/management/keep/beverage/generate",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage",
    path: "/management/keep/beverage",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-scan",
    path: "/management/keep/beverage/scan",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/scan.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer",
    path: "/management/keep/beverage/transfer",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer-receive",
    path: "/management/keep/beverage/transfer/receive",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/receive.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer-send",
    path: "/management/keep/beverage/transfer/send",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/beverage/transfer/send.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-code",
    path: "/management/keep/valet/:code()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/[code].vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-id-edit",
    path: "/management/keep/valet/brand/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-id",
    path: "/management/keep/valet/brand/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand",
    path: "/management/keep/valet/brand",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-new",
    path: "/management/keep/valet/brand/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/brand/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-generate",
    path: "/management/keep/valet/generate",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet",
    path: "/management/keep/valet",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-id-edit",
    path: "/management/keep/valet/place/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-id",
    path: "/management/keep/valet/place/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place",
    path: "/management/keep/valet/place",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-new",
    path: "/management/keep/valet/place/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/place/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-scan",
    path: "/management/keep/valet/scan",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/scan.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-id-edit",
    path: "/management/keep/valet/zone/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-id",
    path: "/management/keep/valet/zone/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone",
    path: "/management/keep/valet/zone",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-new",
    path: "/management/keep/valet/zone/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/keep/valet/zone/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material-id-edit",
    path: "/management/material/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-material-id",
    path: "/management/material/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-id-edit",
    path: "/management/material/group/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-id",
    path: "/management/material/group/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group",
    path: "/management/material/group",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-new",
    path: "/management/material/group/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material",
    path: "/management/material",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-new",
    path: "/management/material/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material-stock",
    path: "/management/material/stock",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/material/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-id-edit",
    path: "/management/menu/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-id",
    path: "/management/menu/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-id-edit",
    path: "/management/menu/group/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-id",
    path: "/management/menu/group/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group",
    path: "/management/menu/group",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-new",
    path: "/management/menu/group/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/group/new/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-new-sub-parent",
    path: "/management/menu/group/new/sub/:parent()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/group/new/sub/[parent].vue").then(m => m.default || m)
  },
  {
    name: "management-menu",
    path: "/management/menu",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-new",
    path: "/management/menu/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-id-edit",
    path: "/management/menu/promotion/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-id",
    path: "/management/menu/promotion/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-id-edit",
    path: "/management/menu/promotion/group/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-id",
    path: "/management/menu/promotion/group/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group",
    path: "/management/menu/promotion/group",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-new",
    path: "/management/menu/promotion/group/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion",
    path: "/management/menu/promotion",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-new",
    path: "/management/menu/promotion/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/promotion/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-id-edit",
    path: "/management/menu/topping/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-id",
    path: "/management/menu/topping/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-id-edit",
    path: "/management/menu/topping/group/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-id",
    path: "/management/menu/topping/group/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group",
    path: "/management/menu/topping/group",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-new",
    path: "/management/menu/topping/group/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping",
    path: "/management/menu/topping",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-new",
    path: "/management/menu/topping/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/topping/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-id-edit",
    path: "/management/menu/type/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/type/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-id",
    path: "/management/menu/type/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/type/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type",
    path: "/management/menu/type",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/type/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-new",
    path: "/management/menu/type/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/menu/type/new.vue").then(m => m.default || m)
  },
  {
    name: "management-report-activity",
    path: "/management/report/activity",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-employee",
    path: "/management/report/employee",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/employee/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-payment",
    path: "/management/report/payment",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-price-report",
    path: "/management/report/price-report",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/price-report/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-product",
    path: "/management/report/product",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/product/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-shift-id",
    path: "/management/report/shift/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/shift/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-report-shift",
    path: "/management/report/shift",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/shift/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-stock",
    path: "/management/report/stock",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-summary",
    path: "/management/report/summary",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/summary/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-void",
    path: "/management/report/void",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/report/void/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-id-edit",
    path: "/management/table/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-table-id",
    path: "/management/table/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-generate",
    path: "/management/table/generate",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-table",
    path: "/management/table",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-new",
    path: "/management/table/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/new.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-id-edit",
    path: "/management/table/zone/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/zone/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-id",
    path: "/management/table/zone/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/zone/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone",
    path: "/management/table/zone",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/zone/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-new",
    path: "/management/table/zone/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/table/zone/new.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-id-edit",
    path: "/management/terminal/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/terminal/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-id",
    path: "/management/terminal/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/terminal/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal",
    path: "/management/terminal",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/terminal/index.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-new",
    path: "/management/terminal/new",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/terminal/new.vue").then(m => m.default || m)
  },
  {
    name: "management-user-id-edit",
    path: "/management/user/:id()/edit",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/user/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-user-id",
    path: "/management/user/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-user",
    path: "/management/user",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/management/user/index.vue").then(m => m.default || m)
  },
  {
    name: "pin-login",
    path: "/pin-login",
    meta: pin_45loginLE3RACtrClMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/pin-login.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "sale-activity-no-id",
    path: "/sale/activity/:no()/:id()",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/sale/activity/[no]/[id].vue").then(m => m.default || m)
  },
  {
    name: "sale-activity",
    path: "/sale/activity",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/sale/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "sale",
    path: "/sale",
    meta: indexDra4eRKlAmMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale-tables",
    path: "/sale/tables",
    meta: indexxcPJTzeyWUMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/sale/tables/index.vue").then(m => m.default || m)
  },
  {
    name: "sale-takeaway",
    path: "/sale/takeaway",
    component: () => import("/home/forge/pos-pre.terradev.app/pages/sale/takeaway/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-access-id",
    path: "/terminal-access/:id()",
    meta: _91id_93CPelJTqf8GMeta || {},
    component: () => import("/home/forge/pos-pre.terradev.app/pages/terminal-access/[id].vue").then(m => m.default || m)
  }
]